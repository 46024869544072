<template >
    <div class="header-quickpay">
        <button 
            class="header-quickpay__button button button_size_small button_color_transp-black button_with_icon"
            :class="{'button_type_active':this.$store.getters.tb_data?.show}"
            @click="handlerClick"
            title="Быстрые выплаты"
        >
        
            <div class="header_button_icon header-quickpay__icon">
                <svg aria-hidden="true" width="20" height="20">
                    <use href="#icon-wallet-2"></use> 
                </svg>
            </div>
        </button>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    methods: {
        ...mapActions([
            'SET_TOPBAR',
            'RESET_TOPBAR'
        ]),
        
        handlerClick() {
            const show = !this.$store.getters.tb_data?.show
            this.RESET_TOPBAR()
            setTimeout(() => {
                this.SET_TOPBAR({name:'pay', show})
            }, 300);
        },
    },
}
</script>
<style lang="scss">
.header-quickpay {
    & &__button {
        margin-right: 0;
    }
}
</style>