
<template>
<div class="custom-select"
        :class="{
            'custom-select_type_error':error,
            'custom-select_type_focus':isFocus,
            'custom-select_type_disabled':disabled
        }"
        v-click-outside="()=>{isFocus=false}"
    >
        <label v-if="label" class="custom-select__label">
            {{label}}
        </label>
        <div class="custom-select__input"
            @click.prevent="()=>{ isFocus=(!isFocus && !$props.disabled) }"
        > 
            <div class="custom-select__input-selector">
                <input type="text" 
                    v-model="inputValue" 
                    @keyup="handlekeyup"
                    :placeholder="placeholder"
                    :disabled="disabled"
                >
            </div>

            <button type="button" class="custom-select__button button_type_static button_type_icon"
                v-if="buttonIcon"
                :class="buttonIconClass"
                @click.stop.prevent = "isFocus=(!isFocus && !$props.disabled); handleClick($event)"
                
            >
                <svg aria-hidden="true" v-if="buttonIcon" :width="iconSize.w" :height="iconSize.h">
                    <use :href="buttonIcon"></use>
                </svg>
            </button>
            
            <button class="custom-select__button" 
                :class="{'is-active':isFocus}"
                :disabled="disabled"
                @click.stop.prevent="()=>{ isFocus=!isFocus }"
            >
                <svg aria-hidden="true" width="16" height="16">
                    <use href="#icon-arrow-down"></use>
                </svg>
            </button>

            <v-list 
                class="dropList_size_full-width"
                :list="filteredList && filteredList.length && filteredList || list" 
                :expand="isFocus && !disabled" 
                :selected = "selectedItem"
                @click="handleClickList($event)"/>
                
        </div>
        <transition name="grow">
            <div v-if="error" class="validation__label validation__label_type_error">
                {{ error }}
            </div>
        </transition>
    </div>
</template>

<script>
import vList from './DropDownList.vue' 
import vButton from './vButton.vue'

export default {
    name:"vCustomSearchSelect",
    props: {
        buttonIcon: {
            type: String,
            default: ''
        },
        buttonIconClass: { 
            type: Object,
            default: () => {}
        },
        iconSize: {
            type: Object,
            default: function() {
                return { w: 20, h: 20 };
            }
        },
        placeholder: {
            type: String,
            default: ''
        },
        name:  {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        error: {
            type: [String,Boolean],
            default: ''
        },
        list: {
            type: [Array],
            required: false,
            default: () => []
        },
        value: {
            default: ''
        },
        disabled:{
            type: Boolean,
            default: false
        },
        relation: {
            type: Object,
            default: () => {}
        },
    },
    components: {
        vList,
        vButton
    },
    watch: {
        value(newVal, oldValue) {
            // this.inputValue = newVal.text
            this.inputValue = ''
            if(!newVal) return
            const selecteditem = this.getFromList(newVal)
            this.inputValue = newVal.name || selecteditem? selecteditem.name : newVal.id
        },
        list() {
            if(!this.value) return
            this.getFromList(this.value)
        },
        'relation.value': function(newVal, oldVal) {
            this.filteredList = this.filterByRelation()
            this.$emit('input', {id:null})
        }
    },
    data() {
        return {
            isFocus: false,
            selectedItem: false,
            inputValue: '',
            filteredList: []
        }
    },
    mounted() {
        let value = this.value

        if(!value) return
        if(typeof value === 'number') {
            value = {id:value}
        }

        if((value.id || value.text) && this.list.length) {
            const itemFromList = this.getFromList(value)
            this.inputValue = itemFromList.name
        } else {
            this.inputValue = value.name
        }

        if(this.relation) {
            this.filteredList = this.filterByRelation()
        }
    },
    methods: {
        handleClickList(event) {
            this.$emit('input', event)
            this.inputValue = event.name
        },
        handleClick(event) {
            this.$emit('buttonClick')
        },
        handlekeyup(event) {
            this.$emit('keyup', this.inputValue)
            this.filterList()
        },
        filterList() {
            if(this.list) {
                const itemFromList = this.getFromList({name: this.inputValue, id: null})
                if(itemFromList) {
                    this.$emit('input', itemFromList)
                }
                if(this.inputValue) {
                    this.filteredList = this.getfilterLsit();
                    if(!this.filteredList.length) {
                        this.filteredList = [{
                            id: 0,
                            name: 'Ничего не найдено',
                            selectable: false
                        }]
                    }
                } else {
                    this.filteredList  = this.relation ? this.filterByRelation() : []
                }
            }
        },
        getFromList(value) {
            let list = this.list
            if(this.relation)  list = this.filterByRelation()
            return list.find(
                item => {
                    return item.id == value.id || item.name === value.text
                }
            );
        },
        getfilterLsit() {
            let list = this.list
            if(this.relation)  list = this.filterByRelation()
            return list.filter(item => {
                return item.id === this.inputValue || this.compareStrings(item.name, this.inputValue) 
            });
        },
        filterByRelation() {
            const ret = this.list.filter(item => {
                return item[this.relation.name] == this.relation.value.name
            })
            return ret
        },
        compareStrings(mainString, searchString) {
            mainString = (''+mainString).toLowerCase();
            searchString = searchString.toLowerCase();
            return mainString.includes(searchString);
        },
        getSelectedItem() {
            return  this.value ? this.getFromList(this.value) : { id: null, name: null }
        }
    },
}
</script> 