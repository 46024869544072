import data from "../../router/views/cabinet/company/form/mixin/data"

export default {
    state: {
        paddingRight: '',
        tb_data: {
            name: false,
            show: false,
            value: false
        }
        
        
    },
    mutations: {
        SET_TOPBAR(state, data) {
            const {name, show, value} = data
            state.tb_data.name = name,
            state.tb_data.show = show,
            state.tb_data.value = value
        },
        HIDEALL_TOPEDBAR(state) {
            state.tb_data.show = false
            state.tb_data.value = false
            state.tb_data.name = false
            // for (const [key] of Object.entries(state)) {
            //     if(key == 'top' || key == 'selected' ) {
            //         state[key] = false
            //     } else {
            //         state[key].isPin ? state[key].value = false: ''
            //     }
            // }
        },
        TOPBAR_SET_PADDINGRIGHT(state,data) {
            state.paddingRight = data
        }
    },
    actions: {
        TRIGGER_TOPBAR({commit,state},name) {
            commit('SET_TOPBAR',{
                name,
                show: data.show || false,
                value: data.value
            })
        },
        SET_TOPBAR({commit},data) {
            commit('SET_TOPBAR',{
                name:data.name,
                show: data.show || false,
                value: data.value
            })
        },
        SELECT_TOPBAR({commit,state}, data) {
            commit('HIDEALL_TOPEDBAR')
            setTimeout(() => {
                commit('SET_TOPBAR',{
                    name: data.name,
                    show: data.show || false,
                    value: data.value
                })
            }, 300);
        },
        HIDEALL_TOPBAR({commit}) {
            commit('HIDEALL_TOPEDBAR')
        },
        RESET_TOPBAR({commit, state}) {
            commit('HIDEALL_TOPEDBAR')
        },
        TOPBAR_SET_PADDINGRIGHT({commit},data) {
            commit('TOPBAR_SET_PADDINGRIGHT', data)
        }
    },
    getters: {
        tb_data: state => state.tb_data,
        topbarSelected: state => state.selected,
        topbarPaddingRight: state => state.paddingRight
    }
}