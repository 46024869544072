<template>
    <div class="custom-area" :class="{
        'custom-area_type_error':error,
        'custom-area_type_focus':isFocus
    }">
        <label :for="name" v-if="label || $slots.label" class="custom-area__label">
            {{label}}
            <slot name="label"></slot>
        </label>

        <div class="custom-area__textarea"  @click="$emit('click')">
            <textarea ref="textarea"
                :style="{ 'min-height': taHeight ? taHeight + 'px' : '' }"
                :name="name"
                :value="value" 
                @input="$emit('input', $event.target.value)" 
                v-on:keypress.ctrl.10.13="handleCtrlEnter()"
                @focus="(e)=>{ isFocus = true; this.$emit('focus', e) }"
                @blur="(e)=>{ isFocus = false; this.$emit('blur', e) }"
                @keyup="handlekeyup"
                :placeholder="placeholder"
                :disabled="disabled"
            />
             
        </div>
        <transition name="grow">
            <div v-if="error" class="validation__label validation__label_type_error">
                {{error || error.message}}
            </div>
        </transition>

        <transition name="grow">
            <div v-if="notes" class="validation__label">
                {{notes}}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'CustomArea',
    props: { 
        notes: {
            type: String,
            default: ''
        },  
        placeholder: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        value: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        },

        label: {
            type: String,
            default: ''
        }, 
        error: {
            type: [String,Boolean,Object],
            default: () => {}
        },
        taHeight: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            isFocus:false
        }
    },
    mounted() {
        if(this.value) this.handlekeyup()
    },
    methods: {
        handleClick() {
            this.$emit('buttonClick',this.$el)
        },
        handleCtrlEnter() {
            const input = this.$el,
                form = input.closest('form, .form'),
                submit = form.querySelector('[type="submit"]')
            if(submit) submit.click()
        },
        handlekeyup() {
            const textarea = this.$refs.textarea
            textarea.style.height = 'auto';
            if(this.value) textarea.style.height = (textarea.scrollHeight+16) + 'px';
        }
    },
}
</script>

<style lang="scss">
    @import '../../../../gulp-src/app/scss/_variables';
    @import '../../../../gulp-src/app/scss/_mixins';
    

    .custom-area {
        color: #919197;
        width: 100%;
        &__textarea {
            display: flex;
            align-items: center;
            width: 100%;
            min-width: 1px;
            position: relative;
            line-height: 24px;
            background: #FFFFFF;
            border: 1px solid #CBCCCF;
            border-radius: 6px;
            outline: none;
            appearance: none;
            overflow: hidden;

            textarea {
                min-width: 100%;
                padding-top: 16px;
                padding-right: 16px;
                padding-left: 16px;
                font-size: 16px;
                width: 100%;
                color: #3A4146;
                @include scrollbar($thumb-color:#3A4146, $track-color:#CBCCCF);
            }
        }
        &__icon {
            display: flex;
            flex-grow: 0;
            
            svg {
                fill: currentColor;
                flex-shrink: 0;
                margin-right: 8px;
            }
        }

        &__label {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #919197;
            margin-bottom: 8px;
            display: inline-block;
        }

        &_type {
            &_focus .custom-area__textarea {
                border-color:#3A4146;
            }
            &_error .custom-area__textarea {
                border-color: #E94A4A
            }
        }

       &.size {
            &_small .custom-area__textarea {
                //height: 32px;
                textarea {
                min-height: 32px;
                }
            }

            &_medium .custom-area__textarea {
                //height: 40px;
                textarea {
                min-height: 40px;
                }
            }

            &_large .custom-area__textarea {
                //height: 56px;
                textarea {
                min-height: 56px;
                }
            }

            &_full-width {
                width: 100%;
            }
       }

        &.on-focus {
            border-color: #3A4146;
        }

        &.disabled {
            background: #FAFAFA;
        }

        .grow {
            &-enter-active, &-leave-active {
                transition: .5s;
            }

            &-enter, &-leave-to {
                max-height: 0;
                opacity: 0;
            }
        }

        &.full-height &__textarea {
            height: calc( 100% - 40px );
        }
        &.full-height textarea {
            height: 100%;
        }
    }
</style>