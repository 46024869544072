
import req from './request'

const DEFAULT_ENDPOINTS = {
    candidates: '/ie/candidates',
    view: '/ie/candidates/view',
    balances: '/ie/candidates/balances',
    reference: '/ie/candidates/reference',
    default: '/ie/default',
    parks: '/ie/default/parks',
    default: '/ie/default',
    defaultView: '/ie/default/view',
    defaultReference: '/ie/default/reference',
    defaultApprove: '/ie/default/approve',
    defaultDecline: '/ie/default/decline',
    defaultArchive: '/ie/default/archive',
    archive: '/ie/archive',
    archivePark: '/ie/archive/park',
    archiveBtl: '/ie/archive/back-to-list',
    archiveReference: '/ie/archive/reference',
    dismissals: '/ie/dismissals',
}

export default {
    candidates(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.candidates, param)
    },
    view(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.view, param)
    },
    balances(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.balances, param)
    },
    reference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    },
    default(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.default, param)
    },
    parks(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.parks, param)
    },
    defaultView(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultView, param)
    },
    defaultReference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultReference, param)
    },
    defaultApprove(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultApprove, param)
    },
    defaultDecline(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultDecline, param)
    },
    defaultArchive(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.defaultArchive, param)
    },
    archive(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.archive, param)
    },
    archivePark(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.archivePark, param)
    },
    archiveBtl(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.archiveBtl, param)
    },
    archiveReference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.archiveReference, param)
    },
    default(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.default, param)
    },
    dismissals(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.dismissals, param)
    }

}