<template>
    <div class="uploader">
        <input ref="file" type="file" @change="onFileChange" :disabled="disabled" v-show="false" />
        <v-custom-input
            class="size_large uploader__input"
            type="text"
            v-model="value"
            :disabled="disabled"
            :placeholder="placeholder"
            :label="label"
            buttonIcon="#icon-close"
            @click="handleFileClick"
        />
    </div>
</template>

<script>
import vCustomInput from '@components/global/CustomInput'
import vButton from '@components/global/vButton'

export default {
    name: 'uploader',
    components: {
        vCustomInput,
        vButton
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            value: '',
        }
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0]
            this.value = file.name
            this.$emit('input', file)
            console.log(file);
        },
        handleFileClick() {
            this.$refs.file.click()
        },
        clear() {
            this.value = ''
        }
    }
}
</script>

<style lang="scss">

</style>